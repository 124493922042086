import * as React from "react"
import Layout from "../components/Layout"
import ListDeal from "../components/ListDeal"
import Seo from "../components/Seo/Seo"

// markup
const Deals = () => {

  return (
    <Layout>

      <Seo
        title={null}
        description={null}
        image={null}
        pathname={'/deals'}
      />

      <main>
        <ListDeal className="bg-zone-3 py-12 px-4 xl:px-0" container={true} />
      </main>
    </Layout>
  )
}

export default Deals
